<script setup lang="ts">
import type { PublicationSummaryFragment } from '~/generated/operations'

export type PublicationSummary = Extract<
	NonNullable<PublicationSummaryFragment>,
	{
		__typename: `publicaties_${string}_Entry`
	}
>

const props = withDefaults(
	defineProps<{
		publication: PublicationSummary
		preventRotateOnClick?: boolean
		disableHover?: boolean
		disableClick?: boolean
		disableFocus?: boolean
		disableLazyLoading?: boolean,
		fetchPriority?: boolean | null,
		onFocus?: () => void
	}>(),
	{
		preventRotateOnClick: false,
		disableHover: false,
		disableClick: false,
		disableFocus: false,
		disableLazyLoading: false,
		fetchPriority: null,
		onFocus: () => {},
	},
)

const flipped = ref(false)
const flip = () => {
	if (!props.preventRotateOnClick) {
		flipped.value = !flipped.value
	}
}
const showCardFront = () => (flipped.value = false)
const showCardBack = () => (flipped.value = true)

defineExpose({
	showCardBack,
	showCardFront,
	flip,
	flipped,
})
</script>

<template>
	<article
		class="card w-[290px] h-[400px] aspect-[290/400] cursor-pointer duration-[.25s] transition-all ease-[cubic-bezier(.05,.92,.6,1.01)] group rounded-xl focus-within:!scale-[1.05]"
		:class="[
			{
				'card--flipped !scale-[1.05]': flipped,
				'hover:!scale-[1.05]': !disableHover,
			},
			`card--${props.publication.typeHandle}`,
		]"
	>
		<div class="card__inner relative h-full rounded-xl transition-all">
			<CardFront
				:tabindex="flipped || disableFocus ? -1 : 0"
				:publication="publication"
				:disableClick="disableClick"
				@click="
					() => {
						if (!disableClick) flip()
					}
				"
				:disable-hover-click="disableHover"
				:disable-lazy-loading="disableLazyLoading"
				:fetch-priority="fetchPriority"
				@focus="onFocus"
			></CardFront>
			<CardPoll
				v-if="publication.typeHandle === 'poll'"
				@flip="flip"
				:visible="flipped"
				:publication="publication"
			/>
			<CardBack
				v-else
				:publication="publication"
				@flip="flip"
				:flipped="flipped"
				:disable-hover="disableHover"
			/>
		</div>
	</article>
</template>

<style scoped>
.card {
	perspective: 900px;
}
.card.card--quiz {
	@apply after:absolute after:h-full after:w-full after:top-0 after:left-0 after:bg-nemo-magenta after:transition after:hover:translate-x-3 after:hover:rotate-[3deg] after:content-[''] after:z-0 after:rounded-xl;
	@apply before:absolute before:h-full before:w-full before:top-0 before:left-0 before:bg-nemo-yellow before:transition before:hover:-translate-x-2 before:hover:translate-y-2 before:hover:rotate-[-3deg] before:content-[''] before:z-0 before:rounded-xl;
}
.card.card--flipped .card__inner {
	transform: rotateY(180deg);
}
.card.card--quiz.card--flipped {
	@apply after:duration-[.25s] after:-translate-x-3  after:translate-y-2 after:rotate-[-3deg];
	@apply before:duration-[.25s] before:translate-x-2 before:translate-y-0 before:rotate-[3deg];
}
.card__back {
	transform: rotateY(180deg);
}
.backface {
	backface-visibility: hidden;
}
.card__inner {
	@apply relative z-10;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}
</style>
