<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components'
import { PollDocument, type PollQuery } from '~/generated/operations'

const props = defineProps<{
	pollId: number | string
	slug: string
	title: string
	visible: boolean
}>()

type PollQueryResult = Extract<
	NonNullable<PollQuery['entry']>,
	{
		__typename: `publicaties_poll_Entry`
	}
>

type PollResult = {
	votes: number
	results: Record<string, number>
}

const didUserVote = useCookie(`poll-${props.pollId}`)
const cachedResults = useCookie<PollResult | undefined>(
	`poll-results-${props.pollId}`,
)

const pollData = ref<PollQueryResult>()
const numVotes = ref(0)
const useCachedResults = ref(false)
const isPollClosed = ref(false)
const showResults = ref(!!didUserVote.value)
const checkboxAnswers = ref<string[]>([])
const userAnswers = computed(() => {
	if (didUserVote.value) return didUserVote.value
	if (pollData.value?.allowMultipleAnswers) return checkboxAnswers.value
	return checkboxAnswers.value.length > 0 ? [checkboxAnswers.value] : []
})

const submit = async () => {
	showResults.value = true
	didUserVote.value = JSON.stringify(userAnswers.value)

	const { votes, results } = await $fetch<PollResult>('/rest/poll', {
		method: 'POST',
		body: {
			pollId: props.pollId,
			optionIds:
				typeof userAnswers.value === 'string'
					? JSON.parse(userAnswers.value)
					: userAnswers.value,
		},
	})

	numVotes.value = votes
	cachedResults.value = {
		votes,
		results,
	}
	useCachedResults.value = true
}

watchImmediate(
	() => props.visible,
	async (visible) => {
		if (!visible) return

		const { data } = await useCachedQuery<PollQuery>({
			key: `poll:${props.slug}`,
			query: PollDocument,
			variables: {
				slug: props.slug,
			},
			tags: [`publication:${props.slug}`, `poll:${props.slug}`],
		})

		pollData.value = data.value.entry
		if (pollData.value?.closingDate) {
			isPollClosed.value =
				new Date().getTime() >
				new Date(pollData.value?.closingDate).getTime()
		}

		numVotes.value = 0
		pollData.value?.pollQuestions.forEach((q) => {
			numVotes.value += q.votes
		})

		if (cachedResults.value && cachedResults.value.votes > numVotes.value) {
			useCachedResults.value = true
			numVotes.value = cachedResults.value.votes
		}
	},
)

const optionsRef = ref()
let haveOptionsBeenVisible = false
const onElementVisibility = (visible: boolean) => {
	if (!visible || haveOptionsBeenVisible) return
	haveOptionsBeenVisible = true
	optionsRef.value.forEach((_: any, index: number) => {
		useMotion(optionsRef.value[index] as HTMLElement, {
			initial: { opacity: 0, y: 5 },
			enter: {
				opacity: 1,
				y: 0,
				transition: {
					delay: index * 100,
				},
			},
		})
	})
}

const resultsBarRef = ref()
const resultsBarBgRef = ref()
let haveResultsBeenVisible = false
const onResultsVisibility = (visible: boolean) => {
	if (!visible || haveResultsBeenVisible) return
	haveResultsBeenVisible = true
	resultsBarRef.value.forEach((_: any, index: number) => {
		useMotion(resultsBarRef.value[index] as HTMLElement, {
			initial: { opacity: 0, y: 5 },
			enter: {
				opacity: 1,
				y: 0,
				transition: {
					delay: index * 100,
				},
			},
		})
	})

	resultsBarBgRef.value.forEach((_: any, index: number) => {
		useMotion(resultsBarBgRef.value[index] as HTMLElement, {
			initial: { translateX: `-100%` },
			enter: {
				translateX: `${results.value[index].offsetX}%`,
				transition: {
					delay: 400 + index * 100,
					duration: 500,
				},
			},
		})
	})
}

const results = computed(() => {
	const results: Array<{
		question: string
		percentage: string
		offsetX: number
	}> = []
	pollData.value?.pollQuestions.forEach((q) => {
		const votes = useCachedResults.value
			? cachedResults.value?.results[q.id!]
			: q.votes

		const percentage = votes / numVotes.value
		results.push({
			question: q.question!,
			percentage: percentage.toLocaleString('nl-NL', {
				style: 'percent',
				minimumFractionDigits: 0,
				maximumFractionDigits: 1,
			}),
			offsetX: percentage === 0 ? -100 : -100 + percentage * 100,
		})
	})
	return results
})
</script>

<template>
	<div v-if="showResults || isPollClosed" class="poll poll--result">
		<h3>
			<slot name="title">{{ title }}</slot>
		</h3>

		<div
			class="poll__results"
			:class="{
				'poll__results--with-scrollbar':
					pollData?.readMorePublication &&
					pollData?.readMorePublication.length > 0
						? results.length > 5
						: results.length > 7,
			}"
		>
			<div
				v-if="cachedResults?.votes || isPollClosed"
				v-for="result in results"
				class="poll__result"
				v-element-visibility="onResultsVisibility"
				ref="resultsBarRef"
			>
				<div class="poll__result__bar" :title="result.question">
					<div class="poll__result__bar__label">
						{{ result.question }}
					</div>
					<div
						class="poll__result__bar__bg"
						:style="{
							transform: `translateX(${result.offsetX}%)`,
						}"
						ref="resultsBarBgRef"
					/>
				</div>
				<div class="poll__result__percentage">
					{{ result.percentage }}
				</div>
			</div>
			<!-- <div v-else>Resultaten worden geladen&hellip;</div> -->
		</div>

		<div class="poll__metadata">
			<div
				class="read-more underline-links"
				v-if="pollData?.readMorePublication.length"
			>
				Meer weten, lees
				<NuxtLink
					:to="useInternalLink(pollData.readMorePublication[0].uri)"
					>{{ pollData.readMorePublication[0].title }}</NuxtLink
				>
			</div>
			<div class="votes">
				<strong>{{ numVotes }}</strong>
				{{ numVotes > 1 ? 'stemmen' : 'stem' }}
			</div>
		</div>
	</div>

	<form v-else class="poll">
		<h3>
			<slot name="title">{{ title }}</slot>
		</h3>
		<div
			v-if="pollData"
			class="poll__options"
			v-element-visibility="onElementVisibility"
		>
			<label
				v-for="option in pollData.pollQuestions"
				:for="`option_${option.id}`"
				ref="optionsRef"
			>
				<input
					name="answer"
					:type="pollData.allowMultipleAnswers ? 'checkbox' : 'radio'"
					v-model="checkboxAnswers"
					:value="option.id"
					:id="`option_${option.id}`"
				/>
				<span>{{ option.question }}</span>
			</label>
		</div>

		<Button
			@click="submit"
			:disabled="userAnswers.length === 0"
			class="self-start submit-btn"
			>Stemmen</Button
		>
	</form>
</template>

<style scoped lang="postcss">
.poll__results--with-scrollbar {
	@apply pr-2;
}

.poll {
	@apply w-[calc(100%_-_30px)] h-full flex flex-col justify-between my-4 pl-2 overflow-auto pr-4;
	scrollbar-color: black transparent;
	scrollbar-width: thin;

	&--result {
		@apply pr-0;
	}

	.poll-teaser & {
		@apply w-auto h-auto overflow-visible;

		&--result {
			@apply pr-4;
		}
	}

	&::-webkit-scrollbar {
		@apply w-[6px];
	}

	&::-webkit-scrollbar-track {
		@apply bg-black/10 rounded-[3px] !w-[3px] mt-8;
	}

	&::-webkit-scrollbar-thumb {
		@apply bg-black rounded-[3px] w-[6px];
	}

	&__options {
		label {
			@apply mb-4  cursor-pointer leading-tight flex opacity-0;
		}
	}

	input[type='radio'],
	input[type='checkbox'] {
		@apply w-[20px] h-[20px] border border-black mr-3 -translate-y-[1px];

		&:checked {
			@apply bg-nemo-green bg-none;
		}

		&:checked,
		&:focus {
			@apply outline-none;
		}
	}

	input[type='checkbox'] {
		@apply rounded-[4px];
	}
}

.poll__result {
	@apply flex text-[14px] tracking-tight mb-2 opacity-0;

	&__bar {
		@apply bg-white rounded-full flex-1 h-[24px] relative overflow-hidden pt-0.5;

		&__label {
			@apply absolute left-0 z-10 text-ellipsis whitespace-nowrap right-1 pl-2 overflow-hidden;
		}

		&__bg {
			@apply bg-nemo-yellow absolute top-0 bottom-0 left-0 w-full rounded-full z-0;
		}
	}

	&__percentage {
		@apply w-12 text-right flex justify-end items-center pt-0.5;
	}
}

.poll__metadata {
	@apply text-[14px] tracking-tight flex flex-col;

	.poll-teaser & {
		@apply flex-col-reverse;

		.read-more {
			@apply mb-0 mt-3;
		}
	}

	.read-more {
		@apply leading-[1.1] mb-3;
	}

	strong {
		@apply font-semibold;
	}
}

h3 {
	@apply font-semibold text-[20px] leading-none max-w-[220px] mb-4;

	.poll-teaser & {
		@apply max-w-none mb-5;
	}
}

.submit-btn {
	@apply relative left-1 bottom-1;
	.poll-teaser & {
		@apply mt-2;
	}
}
</style>
