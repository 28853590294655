<script setup lang="ts">
import type { PublicationSummary } from './index.vue'

const props = defineProps<{
	publication: PublicationSummary
	standalone?: boolean
	disableHover?: boolean
	flipped: boolean
}>()

const introductionMaximized = useMaximizedText(
	props.publication.overrideCardBackText ?? props.publication.introduction,
	200,
)
const image =
	props.publication.header_image && props.publication.header_image.length
		? props.publication.header_image[0]!
		: undefined

const link = useInternalLink(props.publication.uri)

const readTooltip =
	props.publication.typeHandle != 'default' &&
	props.publication.typeHandle != 'facesOfScience' &&
	props.publication.typeHandle != 'quiz'
		? `Bekijk ${props.publication.typeHandle}`
		: props.publication.typeHandle == 'facesOfScience'
			? 'Lees blog'
			: props.publication.typeHandle == 'quiz'
				? 'Doe de quiz'
				: 'Lees artikel'

const fallbackColor = useFallbackColor(props.publication.id)
const author = inject('author', null)

// lazy load video posters
const el = ref()
const isVisible = useElementVisibility(el)
const videoPoster = ref('')
watch(isVisible, (visible) => {
	if (
		visible &&
		(props.publication.__typename == 'publicaties_video_Entry' ||
			props.publication.__typename == 'publicaties_podcast_Entry')
	) {
		videoPoster.value = props.publication.cardVideoThumbUrl ?? ''
	}
})

const videoUrl = ref('')
watch(
	() => props.flipped,
	(isFlipped) => {
		if (
			(props.publication.__typename !== 'publicaties_video_Entry' &&
				props.publication.__typename !== 'publicaties_podcast_Entry') ||
			!props.publication.cardVideoUrl
		) {
			return
		}

		if (isFlipped) {
			videoUrl.value = props.publication.cardVideoUrl
		} else {
			// wait for flip animation to finish
			setTimeout(() => (videoUrl.value = ''), 500)
		}
	},
)
</script>

<template>
	<NuxtLink
		:to="link"
		:tabindex="-1"
		class="card__back z-[1] p-2 absolute w-full h-full backface rounded-xl overflow-hidden flex flex-col justify-between items-center"
		:class="{
			'card__back--light':
				publication.mode === 'light' &&
				publication.__typename !== 'publicaties_quiz_Entry',
			'card__back--dark':
				publication.mode === 'dark' ||
				publication.__typename === 'publicaties_quiz_Entry',
			'!bg-nemo-cyan':
				publication.__typename === 'publicaties_quiz_Entry',
		}"
		:style="{
			backgroundColor:
				image && publication.color != ''
					? publication.color
					: fallbackColor,
			color:
				publication.mode === 'dark' ||
				publication.__typename === 'publicaties_quiz_Entry'
					? '#000'
					: '#fff',
		}"
		ref="el"
	>
		<div
			v-if="
				(publication.__typename === 'publicaties_video_Entry' ||
					publication.__typename === 'publicaties_podcast_Entry') &&
				publication.cardVideoUrl
			"
			class="card__back__video"
		>
			<video
				:src="videoUrl"
				playsinline
				muted
				autoplay
				loop
				:poster="videoPoster"
			/>
		</div>
		<header class="w-full flex justify-between text-[12px]">
			<span
				class="card__back__label"
				v-if="publication.typeHandle !== 'event'"
			>
				{{ publication.dateCreated }}
			</span>
			<div v-else></div>

			<button
				class="card__back__close rounded-full h-6 transition-all ease-out hover:scale-110 hover:rotate-90"
				v-tooltip="'Kaart omdraaien'"
				:tabindex="flipped ? 0 : -1"
				@click.stop.prevent="$emit('flip')"
				aria-label="Kaart omdraaien"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 23 23"
					fill="none"
				>
					<rect
						x="7"
						y="15.4375"
						width="11.9327"
						height="0.795511"
						transform="rotate(-45 7 15.4375)"
					/>
					<rect
						x="7.5625"
						y="7"
						width="11.9327"
						height="0.795511"
						transform="rotate(45 7.5625 7)"
					/>
				</svg>
			</button>
		</header>
		<section
			class="p-8"
			v-tooltip="readTooltip"
			v-if="
				publication.__typename !== 'publicaties_video_Entry' &&
				publication.__typename !== 'publicaties_podcast_Entry'
			"
		>
			<h4
				class="text-center text-body leading-[1.2] mb-3"
				v-html="
					publication.subtitle != '' &&
					introductionMaximized != '' &&
					publication.subtitle !== null &&
					introductionMaximized !== null
						? publication.subtitle
						: publication.title
				"
			></h4>
			<div
				class="text-small text-center leading-[1.35]"
				v-html="introductionMaximized"
			></div>
		</section>
		<div v-else class="w-full h-full" v-tooltip="readTooltip" />
		<footer class="card__back__row" v-tooltip="readTooltip">
			<div
				class="card__back__label flex items-center gap-1"
				v-if="
					(author ||
						(publication.profiles &&
							publication.profiles.length)) &&
					publication.__typename !== 'publicaties_slideshow_Entry' &&
					!(
						(publication.__typename === 'publicaties_video_Entry' ||
							publication.__typename ===
								'publicaties_podcast_Entry') &&
						publication.cardVideoUrl
					)
				"
			>
				{{
					author?.title ??
					(publication.profiles && publication.profiles.length
						? publication.profiles[0].title
						: '')
				}}
				<span v-if="publication.profiles.length > 1">
					+{{ publication.profiles.length - 1 }}
				</span>
			</div>

			<div v-else></div>
			<span
				:tabindex="flipped ? 0 : -1"
				class="sm:opacity-0 sm:focus:opacity-100 card__back__label text-black font-semibold !bg-nemo-green transition-all ease-in duration-200"
			>
				{{ readTooltip }}
			</span>
		</footer>
	</NuxtLink>
</template>

<style scoped lang="postcss">
.card__back {
	outline: 1px solid transparent;
}
.card__back__label {
	@apply rounded-full h-6 px-3 py-1 pt-[0.65em] leading-none;
}
.card__back--light .card__back__label,
.card__back--light .card__back__close {
	@apply bg-black/20;
}

.card__back--light rect {
	@apply fill-white;
}
.card__back--dark .card__back__label,
.card__back--dark .card__back__close {
	@apply bg-black/10;

	.card--video & {
		@apply bg-black/50 text-white;
	}
}

.card__back--dark rect {
	@apply fill-black;

	.card--video & {
		@apply fill-white;
	}
}
.card__back__row {
	@apply w-full flex justify-between text-[11px] shrink-0;
}

.card__back__video {
	position: absolute;
	left: -3px;
	right: -3px;
	top: -3px;
	bottom: -3px;
	z-index: -1;

	video {
		@apply w-[calc(100%_+6px)] h-[calc(100%_+6px)] object-cover;
	}
}
</style>
